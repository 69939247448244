import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { financeiroStore, mainStore, pixStore, plugnotasConfiguracaoStore, plugnotasNfceProdutoStore, plugnotasNfeStore, plugnotasStore, } from '@/store';
let PlugnotasVisualizarNfDialog = class PlugnotasVisualizarNfDialog extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
        this.loading = false;
        this.loadingDownloadXMLCce = false;
        this.loadingDownloadPDFCce = false;
        this.dados = {
            nota: [],
            financeiro: {}
        };
        this.financeiroParcelaMovimentacaoId = null;
        this.financeiroParcelaId = null;
        this.financeiroId = null;
        this.nfeId = null;
        this.tipoNota = 'NFSE';
    }
    get nota() {
        return this.dados.nota[0];
    }
    get notaCancelamento() {
        return this.nota.cancelamento;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async plugnotasNfseEmitir() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente re-emitir a NFS-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasStore.plugnotasNfseEmitirFinanceiroParcelaMovimentacao(this.financeiroParcelaMovimentacaoId);
            }
        });
    }
    async plugnotasNfseEmitirFinanceiroParcela() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente re-emitir a NFS-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasStore.plugnotasNfseEmitirFinanceiroParcela(this.financeiroParcelaId);
            }
        });
    }
    async plugnotasNfceEmitir() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente re-emitir a NFC-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasNfceProdutoStore.plugnotasNfceEmitirFinanceiro(this.financeiroId);
            }
        });
    }
    async plugnotasNfeEmitir() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente re-emitir a NF-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasStore.plugnotasNfeEmitirFinanceiro(this.financeiroId);
            }
        });
    }
    async plugnotasNfeEmitirTrocaDevolucao() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente re-emitir a NF-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasStore.plugnotasNfeReemitirTroca(this.nfeId);
            }
        });
    }
    async plugnotasNfceEmitirFinanceiroParcela() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente re-emitir a NFC-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasNfceProdutoStore.plugnotasNfceEmitirFinanceiroParcela(this.financeiroParcelaId);
            }
        });
    }
    async plugnotasSolicitarCancelamento(tipoNota, plugnotasId) {
        this.$swal({
            title: `Deseja realmente Solicitar o cancelamento da nota ${tipoNota}`,
            text: 'Ao solicitar o cancelamento a nota não será cancelada imediatamente, ela será avaliada se é possível fazer o cancelamento pelo tempo.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasNfceProdutoStore.plugnotasSolicitarCancelamento({ tipoNota, plugnotasId });
            }
        });
    }
    async temSaldoEmissaoNota() {
        const plugnotasConfiguracao = await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
        if (!plugnotasConfiguracao || plugnotasConfiguracao.credito_quantidade <= 0) {
            this.$swal({
                text: 'Saldo insuficiente para a emissão da nota',
                icon: 'error',
            });
            return false;
        }
        return true;
    }
    async show(financeiroParcelaMovimentacaoId) {
        this.tipoNota = 'NFSE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasStore.plugnotasNfseConsultar(financeiroParcelaMovimentacaoId);
        this.financeiroParcelaMovimentacaoId = financeiroParcelaMovimentacaoId;
        await pixStore.atualizaStatusPlugnotas(this.dados.financeiro);
        this.loading = false;
    }
    async showNfseFinanceiroParcela(financeiroParcelaId) {
        this.tipoNota = 'NFSE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasStore.plugnotasNfseConsultarFinanceiroParcela(financeiroParcelaId);
        this.financeiroParcelaId = financeiroParcelaId;
        await pixStore.atualizaStatusPlugnotas(this.dados.financeiro);
        this.loading = false;
    }
    async showNfceFinanceiro(financeiroId) {
        this.tipoNota = 'NFCE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasStore.plugnotasNfceConsultarFinanceiro(financeiroId);
        this.financeiroId = financeiroId;
        await financeiroStore.atualizaStatusPlugnotas(this.dados.financeiro);
        this.loading = false;
    }
    async showNfeFinanceiro(financeiroId) {
        this.tipoNota = 'NFE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasStore.plugnotasNfeConsultarFinanceiro(financeiroId);
        this.financeiroId = financeiroId;
        await financeiroStore.atualizaStatusPlugnotas(this.dados.financeiro);
        this.loading = false;
    }
    async showNfeTrocaDevolucao(nfeId) {
        this.tipoNota = 'NFE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasNfeStore.plugnotasNfeConsultarTrocaDevolucao(nfeId);
        this.nfeId = nfeId;
        this.loading = false;
    }
    async showNfceFinanceiroParcela(financeiroParcelaId) {
        this.tipoNota = 'NFCE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasStore.plugnotasNfceConsultarFinanceiroParcela(financeiroParcelaId);
        this.financeiroParcelaId = financeiroParcelaId;
        this.loading = false;
    }
    async showNfceFinanceiroParcelaMovimentacao(financeiroParcelaMovimentacaoId) {
        this.tipoNota = 'NFCE';
        this.dialog = true;
        this.loading = true;
        this.dados = await plugnotasStore.plugnotasNfseConsultar(financeiroParcelaMovimentacaoId);
        this.financeiroParcelaMovimentacaoId = financeiroParcelaMovimentacaoId;
        this.dados = this.dados[0];
        this.loading = false;
    }
    async close() {
        this.dialog = false;
        this.dados = {};
    }
    async downloadPdfCce() {
        this.loadingDownloadPDFCce = true;
        await plugnotasStore.plugnotasNfePdfDownloadCce(this.nota.id);
        this.loadingDownloadPDFCce = false;
    }
    async downloadXmlCce() {
        this.loadingDownloadXMLCce = true;
        const response = await plugnotasStore.plugnotasNfeXmlDownloadCce(this.nota.id);
        const xmltext = response;
        const filename = `${this.nota.id}_cce.xml`;
        const pom = document.createElement('a');
        const bb = new Blob([xmltext], { type: 'text/plain' });
        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);
        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        pom.click();
        this.loadingDownloadXMLCce = false;
    }
};
PlugnotasVisualizarNfDialog = __decorate([
    Component
], PlugnotasVisualizarNfDialog);
export default PlugnotasVisualizarNfDialog;
