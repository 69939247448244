import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { logStore } from '@/store';
let LogShowComponent = class LogShowComponent extends Vue {
    constructor() {
        super(...arguments);
        this.customParams = {};
        this.loading = true;
        this.isLoadingDetail = false;
        this.id = 0;
        this.log = [];
        this.dialog = false;
        this.detailsItems = [];
        this.operationsHeaders = [
            {
                text: 'Usuário',
                sortable: true,
                value: 'created_by_name',
                align: 'left',
            },
            {
                text: 'Data',
                sortable: true,
                value: 'created_at',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.operationsItems = [];
        this.detailsHeaders = [
            {
                text: 'Campo',
                sortable: true,
                value: 'field',
                align: 'left',
            },
            {
                text: 'Antes',
                sortable: true,
                value: 'before',
                align: 'left',
            },
            {
                text: 'Depois',
                sortable: true,
                value: 'after',
                align: 'left',
            },
        ];
    }
    onUpdateId(val) {
        this.id = val;
    }
    async show(id) {
        if (id) {
            this.id = id;
        }
        this.loading = true;
        const params = {
            model: this.propModel,
            modelId: this.id,
        };
        const payload = {
            params,
            customParams: this.customParams
        };
        this.dialog = true;
        this.detailsItems = [];
        this.log = await logStore.getLog(payload);
        this.loading = false;
    }
    async setCustomParams(params) {
        this.customParams = params;
    }
    async showDetail(item) {
        const model = item.model;
        const modelId = item.model_id;
        this.isLoadingDetail = true;
        const logId = item.id;
        this.detailsItems = await logStore.getLogById({ params: { logId, model, modelId }, customParams: this.customParams });
        this.isLoadingDetail = false;
    }
};
__decorate([
    Prop(Number)
], LogShowComponent.prototype, "propModelId", void 0);
__decorate([
    Prop(String)
], LogShowComponent.prototype, "propModel", void 0);
__decorate([
    Watch('propModelId')
], LogShowComponent.prototype, "onUpdateId", null);
LogShowComponent = __decorate([
    Component
], LogShowComponent);
export default LogShowComponent;
