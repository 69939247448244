import { render, staticRenderFns } from "./LogShowComponent.vue?vue&type=template&id=6c8f5104&scoped=true&"
import script from "./LogShowComponent.vue?vue&type=script&lang=ts&"
export * from "./LogShowComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c8f5104",
  null
  
)

export default component.exports