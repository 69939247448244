import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { plugnotasConfiguracaoStore, plugnotasNfceProdutoStore, plugnotasStore } from '@/store';
import PlugnotasVisualizarNfDialog from '@/components/PlugnotasVisualizarNfDialog.vue';
let PlugnotasConsultaEmiteButton = class PlugnotasConsultaEmiteButton extends Vue {
    constructor() {
        super(...arguments);
        this.plugnotasId = null;
        this.plugnotasStatus = null;
        this.dialogFranchisePreInvoiceVisualiza = false;
        this.loadingDownloadPDF = false;
        this.loadingDownloadXML = false;
        this.emitirNFCE = false;
        this.emitirNFE = false;
        this.dialogCC = false;
        this.textoParaCorrecao = '';
    }
    get dataHoraEvento() {
        return format(new Date(), 'dd/MM/yyyy HH:mm');
    }
    get getConsultaNfClass() {
        if (this.plugnotasStatus == 'CONCLUIDO') {
            return 'ma-2 success';
        }
        else if (this.plugnotasStatus == 'PROCESSANDO') {
            return 'ma-2 warning';
        }
        else {
            return 'ma-2 error';
        }
    }
    get getConsultaNfLabel() {
        if (this.plugnotasStatus == 'CONCLUIDO') {
            return 'consultar';
        }
        else if (this.plugnotasStatus == 'PROCESSANDO') {
            return 'Em processamento';
        }
        else {
            return this.plugnotasStatus;
        }
    }
    get documentoEmitidoPlugnotas() {
        return this.plugnotasId && this.plugnotasId != 0;
    }
    get documentoEmitidoPrefeitura() {
        return this.plugnotasId == 0;
    }
    get documentoNaoEmitido() {
        return !this.plugnotasId;
    }
    async plugnotasNfseEmitirFinanceiroParcela() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NFS-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasStore.plugnotasNfseEmitirFinanceiroParcela(this.propFinanceiroParcelaId);
            }
        });
    }
    async plugnotasNfseEmitir() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NFS-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasStore.plugnotasNfseEmitirFinanceiroParcelaMovimentacao(this.propFinanceiroParcelaMovimentacaoId);
            }
        });
    }
    async plugnotasNfceEmitirFinanceiro() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NFC-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.emitirNFCE = true;
                const response = await plugnotasNfceProdutoStore.plugnotasNfceEmitirFinanceiro(this.propFinanceiroId);
                if (response) {
                    this.plugnotasId = response.plugnotas_id;
                    this.plugnotasStatus = 'PROCESSANDO';
                }
                this.emitirNFCE = false;
            }
        });
    }
    async plugnotasNfeReemitirTroca() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NF-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.emitirNFE = true;
                const response = await plugnotasStore.plugnotasNfeReemitirTroca(this.propFinanceiroId);
                if (response) {
                    this.plugnotasId = response.plugnotas_id;
                    this.plugnotasStatus = 'PROCESSANDO';
                }
                this.emitirNFE = false;
            }
        });
    }
    async plugnotasNfeEmitirFinanceiro() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NF-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.emitirNFE = true;
                const response = await plugnotasStore.plugnotasNfeEmitirFinanceiro(this.propFinanceiroId);
                if (response) {
                    this.plugnotasId = response.plugnotas_id;
                    this.plugnotasStatus = 'PROCESSANDO';
                }
                this.emitirNFE = false;
            }
        });
    }
    async plugnotasNfceEmitirFinanceiroParcelaMovimentacao() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NFC-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasNfceProdutoStore.plugnotasNfceEmitirFinanceiroParcelaMovimentacao(this.propFinanceiroParcelaMovimentacaoId);
            }
        });
    }
    async plugnotasNfceEmitirFinanceiroParcela() {
        if (!this.temSaldoEmissaoNota()) {
            return;
        }
        this.$swal({
            title: 'Deseja realmente emitir a NFC-e?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await plugnotasNfceProdutoStore.plugnotasNfceEmitirFinanceiroParcela(this.propFinanceiroParcelaId);
            }
        });
    }
    async plugnotasNfseCancelar() {
        await plugnotasStore.plugnotasNfseCancelar(this.plugnotasId);
    }
    async downloadXml() {
        this.loadingDownloadXML = true;
        let response = null;
        if (this.propTipoDocumento === 1) {
            response = await plugnotasStore.plugnotasNfseXmlDownload(this.plugnotasId);
        }
        else if (this.propTipoDocumento === 2) {
            response = await plugnotasStore.plugnotasNfceXmlDownload(this.plugnotasId);
        }
        else if (this.propTipoDocumento === 3 || this.propTipoDocumento === 4) {
            response = await plugnotasStore.plugnotasNfeXmlDownload(this.plugnotasId);
        }
        const xmltext = response;
        const filename = `${this.plugnotasId}.xml`;
        const pom = document.createElement('a');
        const bb = new Blob([xmltext], { type: 'text/plain' });
        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);
        pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        pom.click();
        this.loadingDownloadXML = false;
    }
    async downloadPdf() {
        this.loadingDownloadPDF = true;
        if (this.propTipoDocumento === 1) {
            await plugnotasStore.plugnotasNfsePdfDownload(this.plugnotasId);
        }
        else if (this.propTipoDocumento === 2) {
            await plugnotasStore.plugnotasNfcePdfDownload(this.plugnotasId);
        }
        else if (this.propTipoDocumento === 3 || this.propTipoDocumento === 4) {
            await plugnotasStore.plugnotasNfePdfDownload(this.plugnotasId);
        }
        this.loadingDownloadPDF = false;
    }
    async handleEnviarCartaCorrecao() {
        await plugnotasStore.enviarCartaCorrecao({ plugnotasId: this.plugnotasId, textoParaCorrecao: this.textoParaCorrecao });
    }
    async temSaldoEmissaoNota() {
        const plugnotasConfiguracao = await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
        if (!plugnotasConfiguracao || plugnotasConfiguracao.credito_quantidade <= 0) {
            this.$swal({
                text: 'Saldo insuficiente para a emissão da nota',
                icon: 'error',
            });
            return false;
        }
        return true;
    }
    async changePlugnotasStatus(val) {
        this.plugnotasStatus = this.propPlugnotasStatus;
    }
    async changePlugnotasId(val) {
        this.plugnotasId = this.propPlugnotasId;
    }
};
__decorate([
    Prop({ type: String, default: null, })
], PlugnotasConsultaEmiteButton.prototype, "propPlugnotasId", void 0);
__decorate([
    Prop({ type: String, default: null })
], PlugnotasConsultaEmiteButton.prototype, "propPlugnotasStatus", void 0);
__decorate([
    Prop({ type: Number, default: null })
], PlugnotasConsultaEmiteButton.prototype, "propFinanceiroId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], PlugnotasConsultaEmiteButton.prototype, "propFinanceiroParcelaId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], PlugnotasConsultaEmiteButton.prototype, "propFinanceiroParcelaMovimentacaoId", void 0);
__decorate([
    Prop({ type: Number, required: true })
], PlugnotasConsultaEmiteButton.prototype, "propTipoDocumento", void 0);
__decorate([
    Watch('propPlugnotasStatus', { immediate: true })
], PlugnotasConsultaEmiteButton.prototype, "changePlugnotasStatus", null);
__decorate([
    Watch('propPlugnotasId', { immediate: true })
], PlugnotasConsultaEmiteButton.prototype, "changePlugnotasId", null);
PlugnotasConsultaEmiteButton = __decorate([
    Component({
        components: {
            PlugnotasVisualizarNfDialog,
        },
    })
], PlugnotasConsultaEmiteButton);
export default PlugnotasConsultaEmiteButton;
