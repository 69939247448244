import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import { startOfWeek, endOfMonth } from 'date-fns';
let PeriodSelectorComponent = class PeriodSelectorComponent extends Vue {
    constructor() {
        super(...arguments);
        this.dataInicial = null;
        this.dataFinal = null;
        this.loading = false;
        this.items = [
            { id: 0, name: 'Hoje' },
            { id: 1, name: 'Essa semana' },
            { id: 2, name: 'Esse mês' },
            { id: 3, name: 'Esse ano' },
            { id: 4, name: 'Ultimos 7 dias' },
            { id: 5, name: 'Ultimos 30 dias' },
            { id: 6, name: 'Mês anterior' },
            { id: 7, name: 'Próximo Mês' },
        ];
        // @ts-ignore
        this.selectedItem = this.propTypeDefault;
    }
    onChangeDefault(val) {
        this.setDefault();
    }
    onChangeValue(val) {
        this.dataInicial = val[0];
        this.dataFinal = val[1];
    }
    onChangeHandlerInicial(val) {
        this.$emit('input', [val, this.dataFinal]);
    }
    onChangeHandlerFinal(val) {
        this.$emit('input', [this.dataInicial, val]);
    }
    setDefault() {
        const date = new Date();
        switch (this.selectedItem) {
            case 0:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                this.dataFinal = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                break;
            case 1:
                this.dataInicial = startOfWeek(date, { weekStartsOn: 1 });
                this.dataFinal = new Date(this.dataInicial.getFullYear(), this.dataInicial.getMonth(), this.dataInicial.getDate() + 6);
                break;
            case 2:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
                this.dataFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
            case 3:
                this.dataInicial = new Date(date.getFullYear(), 0, 1);
                this.dataFinal = new Date(date.getFullYear(), 11, 31);
                break;
            case 4:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
                this.dataFinal = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                break;
            case 5:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
                this.dataFinal = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                break;
            case 6:
                const mesAnterior = date.getMonth() - 1;
                this.dataInicial = new Date(date.getFullYear(), mesAnterior, 1);
                const dataUltimoDiaMesAnterior = endOfMonth(this.dataInicial);
                this.dataFinal = new Date(dataUltimoDiaMesAnterior.getFullYear(), dataUltimoDiaMesAnterior.getMonth(), dataUltimoDiaMesAnterior.getDate());
                break;
            case 7:
                const proximoMes = date.getMonth() + 1;
                const dataUltimoDiaProximoMes = endOfMonth(new Date());
                this.dataInicial = new Date(dataUltimoDiaProximoMes.getFullYear(), proximoMes, 1);
                this.dataFinal = new Date(dataUltimoDiaProximoMes.getFullYear(), proximoMes, dataUltimoDiaProximoMes.getDate());
                break;
            default:
                this.dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);
                this.dataFinal = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                break;
        }
        this.dataInicial = this.dataInicial.toISOString().split('T')[0];
        this.dataFinal = this.dataFinal.toISOString().split('T')[0];
    }
    async validateFields() {
        // @ts-ignore
        const isValidInicial = await this.$refs.dataInicial.$refs.observer.validate();
        // @ts-ignore
        const isValidFinal = await this.$refs.dataFinal.$refs.observer.validate();
        return isValidInicial && isValidFinal;
    }
    mounted() {
        if (this.propPermiteNull) {
            return;
        }
        this.loading = true;
        this.setDefault();
        this.loading = false;
    }
};
__decorate([
    Prop({ type: String, default: 'Data' })
], PeriodSelectorComponent.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: Number, default: 2 })
], PeriodSelectorComponent.prototype, "propTypeDefault", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], PeriodSelectorComponent.prototype, "propPermiteNull", void 0);
__decorate([
    Prop()
], PeriodSelectorComponent.prototype, "value", void 0);
__decorate([
    Watch('selectedItem')
], PeriodSelectorComponent.prototype, "onChangeDefault", null);
__decorate([
    Watch('value')
], PeriodSelectorComponent.prototype, "onChangeValue", null);
__decorate([
    Watch('dataInicial')
], PeriodSelectorComponent.prototype, "onChangeHandlerInicial", null);
__decorate([
    Watch('dataFinal')
], PeriodSelectorComponent.prototype, "onChangeHandlerFinal", null);
PeriodSelectorComponent = __decorate([
    Component({
        components: {
            DateFieldComponent,
        },
    })
], PeriodSelectorComponent);
export default PeriodSelectorComponent;
